import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import L from "leaflet";
import "../Styles/QRgenerator.css";
import ApiRoutes from "../ApiRoutes.js";
import { onMessageListener } from "../firebase";
import "leaflet/dist/leaflet.css";
import axiosInstance from "../axiosInstance.js";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import searchIcon from "../Assets/searchIcon.svg";
import Header from "../Components/Header.js";
import NavBar from "../Components/Navbar.js";
import TimeSlotsCalendar from "../Components/TimeSlotsCalendar.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { logDOM } from "@testing-library/react";

const customIcon = new L.Icon({
  iconUrl: require("../Assets/Vectorlocation.png"),
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

function QueueForm() {
  const [formEdited, setFormEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    queueName: "",
    companyName: "",
    geoLocation: "",
    streetName: "",
    district: "",
    countryName: "",
    state: "",
    pincode: "",
    availableFromTime: localStorage.getItem("businessStartTime") || "08:00",
    availableToTime: localStorage.getItem("businessEndTime") || "17:00",
  });
  const [existingQueueDetails, setExistingQueueDetails] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(
    existingQueueDetails
      ? [existingQueueDetails.queueLat, existingQueueDetails.queueLon]
      : null
  );
  const [locationChanged, setLocationChanged] = useState(false);
  const [mapCenter, setMapCenter] = useState(
    existingQueueDetails
      ? [existingQueueDetails.queueLat, existingQueueDetails.queueLon]
      : [13.0732357652622, 80.27119029651989]
  );
  const [isExistingQueue, setIsExistingQueue] = useState(false);
  const [activeSection, setActiveSection] = useState("generalInfo"); // Initially set to generalInfo
  const [addSlotPopup, setAddSlotPopup] = useState(false);
  const isFirstRun = useRef(true);
  const [isSlotAdded, setIsSlotAdded] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });

  const navigate = useNavigate();
  const setupMessageListener = () => {
    onMessageListener().then((payload) => {
      toast.info("Token Updated", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {},
      });
    });
  };
  const handleToggle = async (day) => {
    setDaysOfWeek((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));

    const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

    try {
      const updatedDaysOfWeek = {
        ...daysOfWeek,
        [day]: !daysOfWeek[day],
      };

      const workingDaysString = Object.keys(updatedDaysOfWeek)
        .map((d) => (updatedDaysOfWeek[d] ? "1" : "0"))
        .join("");

      setDaysOfWeek(updatedDaysOfWeek);

      const queueId = companyDetails.queues[0].queueId;
      const queueLat = companyDetails.queues[0].queueLat;
      const queueLon = companyDetails.queues[0].queueLon;
      const queueName = companyDetails.queues[0].queueName;
      const workflowId = localStorage.getItem("workflowId");

      axiosInstance
        .put(`${ApiRoutes.apiBaseUrl}/company/workflow/${workflowId}/queue`, {
          queueId: queueId,
          queueLat: queueLat,
          queueLon: queueLon,
          queueName: queueName,
          qworkingDays: workingDaysString,
        })
        .then((response) => {
          // Update the workingDays in companyDetails after successful API call
          const updatedCompanyDetails = {
            ...companyDetails,
            queues: [
              {
                ...companyDetails.queues[0],
                qworkingDays: workingDaysString,
              },
            ],
          };

          localStorage.setItem(
            "companyDetails",
            JSON.stringify(updatedCompanyDetails)
          );
        })
        .catch((error) => {
          console.error("Error updating queue:", error);
        });
    } catch (error) {
      console.error("Error updating queue:", error);
    }
  };

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId) {
      axiosInstance
        .get(`${ApiRoutes.apiBaseUrl}/company/${companyId}`)
        .then((response) => {
          const companyDetails = response.data;

          localStorage.setItem(
            "companyDetails",
            JSON.stringify(companyDetails)
          );
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    }

    if (isFirstRun.current) {
      isFirstRun.current = false;
      const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
      if (companyDetails && companyDetails.queues && companyDetails.queues[0]) {
        const workingDaysString = companyDetails.queues[0].qworkingDays;
        if (workingDaysString) {
          const workingDaysArray = workingDaysString
            .split("")
            .map((char) => char === "1");
          setDaysOfWeek({
            Mon: workingDaysArray[0],
            Tue: workingDaysArray[1],
            Wed: workingDaysArray[2],
            Thu: workingDaysArray[3],
            Fri: workingDaysArray[4],
            Sat: workingDaysArray[5],
            Sun: workingDaysArray[6],
          });
        }
      }

      const fetchUserLocation = async () => {
        try {
          if (
            companyDetails &&
            Array.isArray(companyDetails.queues) &&
            companyDetails.queues.length > 0 &&
            companyDetails.queues[0].queueLat !== undefined &&
            companyDetails.queues[0].queueLon !== undefined
          ) {
            const existingQueue = companyDetails.queues[0];
            const queueLat = parseFloat(existingQueue.queueLat);
            const queueLon = parseFloat(existingQueue.queueLon);

            setExistingQueueDetails({
              queueName: existingQueue.queueName,
              geoLocation: `${queueLat}, ${queueLon}`,
              availableFromTime: existingQueue.businessStartTime,
              streetName: existingQueue.qstreet,
              pincode: existingQueue.qpincode,
            });
            setIsExistingQueue(true);

            setFormData((prevData) => ({
              ...prevData,
              queueName: existingQueue.queueName || "",
              geoLocation: `${queueLat}, ${queueLon}` || "",
              availableFromTime: existingQueue.businessStartTime,
              availableToTime: existingQueue.businessEndTime,
              streetName: existingQueue.qstreet,
              pincode: existingQueue.qpincode,
            }));

            setMarkerPosition([queueLat, queueLon]);

            axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${queueLat}&lon=${queueLon}&addressdetails=1`
              )
              .then((response) => {
                const address = response.data.address;
                const companyDetails = JSON.parse(
                  localStorage.getItem("companyDetails")
                );

                const updatedFormData = {
                  streetName:
                    companyDetails?.queues[0]?.qstreet || address.road || "",
                  district: address.city || address.county || "",
                  state: address.state || "",
                  countryName: address.country || "",
                  pincode:
                    companyDetails?.queues[0]?.qpincode ||
                    address.postcode ||
                    "",
                };

                setFormData((prevData) => ({
                  ...prevData,
                  ...updatedFormData,
                }));
              })
              .catch((error) => {
                console.error("Error fetching address details:", error);
              });
          } else {
            if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const userLat = position.coords.latitude;
                  const userLon = position.coords.longitude;

                  setMarkerPosition([userLat, userLon]);

                  axios
                    .get(
                      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${userLat}&lon=${userLon}&addressdetails=1`
                    )
                    .then((response) => {
                      const address = response.data.address;
                      setFormData((prevData) => ({
                        ...prevData,
                        streetName: address.road || "",
                        district: address.city || address.county || "",
                        state: address.state || "",
                        countryName: address.country || "",
                        pincode: address.postcode || "",
                      }));
                    })
                    .catch((error) => {
                      console.error("Error fetching address details:", error);
                    });

                  setMapCenter([userLat, userLon]);
                  setFormData((prevData) => ({
                    ...prevData,
                    geoLocation: `${userLat}, ${userLon}`,
                  }));
                },
                (error) => {
                  console.error("Error fetching user's location:", error);
                  window.alert(
                    "Your browser location is turned off, please turn it on and try logging in again"
                  );
                  navigate("/");

                  setMapCenter([]);
                }
              );
            } else {
              console.error("Geolocation is not supported");

              setMapCenter([]);
            }
          }
        } catch (error) {
          console.error("Error of the geolocation:", error);
        }
      };
      setupMessageListener();
      fetchUserLocation();
    }
  }, []);
  // const handleSlotPopup = () =>{
  //   setAddSlotPopup(true);
  // }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setLocationChanged(true);
    setFormEdited(true);

    if (name === "streetName" || name === "pincode") {
      const updatedCompanyDetails = JSON.parse(
        localStorage.getItem("companyDetails")
      );

      if (updatedCompanyDetails) {
        updatedCompanyDetails.queues[0][`q${name}`] = value;
        localStorage.setItem(
          "companyDetails",
          JSON.stringify(updatedCompanyDetails)
        );
      }
    }
  };
  // eslint-disable-next-line no-unused-vars
  const unusedVariable = locationChanged;
  const handleAddSlot = async () => {
    try {
      setLoading(true);
      const slotName = document.getElementById("slotName").value;
      const startTime = document.getElementById("startTime").value;
      const endTime = document.getElementById("endTime").value;

      const [startHour, startMinute] = startTime.split(":");
      const [endHour, endMinute] = endTime.split(":");

      if (!slotName.trim()) {
        toast.error("Slot name cannot be empty");
        setLoading(false);
        return;
      }

      const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
      const queuesArray = companyDetails?.queues || [];
      const businessStartTime =
        queuesArray.length > 0 ? queuesArray[0].businessStartTime : "09:00";
      const businessEndTime =
        queuesArray.length > 0 ? queuesArray[0].businessEndTime : "17:00";

      const slotAvailable =
        document.getElementById("slotAvailability").value === "available";

      if (
        isSlotWithinBusinessHours(
          startHour,
          startMinute,
          endHour,
          endMinute,
          businessStartTime,
          businessEndTime
        )
      ) {
        const queueId = queuesArray.length > 0 ? queuesArray[0].queueId : null;

        if (queueId !== null) {
          const slotData = {
            queueId,
            slotAvailable,
            slotEndTime: {
              hour: endHour,
              minute: endMinute,
              nano: 0,
              second: "00",
            },
            slotName,
            slotStartTime: {
              hour: startHour,
              minute: startMinute,
              nano: 0,
              second: "00",
            },
          };

          const response = await axiosInstance.post(
            `${ApiRoutes.apiBaseUrl}/company/workflow/create-slot`,
            slotData
          );

          toast.success("New slot created");
          setAddSlotPopup(false);
          setIsSlotAdded(!isSlotAdded);
        } else {
          console.error("No queueId found in companyDetails!");
        }
      } else {
        toast.error("Selected slot is outside business hours");
      }
    } catch (error) {
      console.error("Error creating slot:", error);
    } finally {
      setLoading(false); // Stop loading in both success and error cases
    }
  };

  const isSlotWithinBusinessHours = (
    startHour,
    startMinute,
    endHour,
    endMinute,
    businessStartTime,
    businessEndTime
  ) => {
    const slotStart = new Date(0, 0, 0, startHour, startMinute);
    const slotEnd = new Date(0, 0, 0, endHour, endMinute);
    const businessStart = new Date(0, 0, 0, ...businessStartTime.split(":"));
    const businessEnd = new Date(0, 0, 0, ...businessEndTime.split(":"));

    return slotStart >= businessStart && slotEnd <= businessEnd;
  };

  const handleMapSearch = (latLng) => {
    const newGeoLocation = `${latLng.lat}, ${latLng.lng}`;

    if (newGeoLocation !== formData.geoLocation) {
      setFormData((prevData) => ({
        ...prevData,
        geoLocation: newGeoLocation,
      }));
      setFormEdited(true);

      setMarkerPosition([latLng.lat, latLng.lng]);

      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latLng.lat}&lon=${latLng.lng}&addressdetails=1`
        )
        .then((response) => {
          const address = response.data.address;
          setFormData((prevData) => ({
            ...prevData,
            streetName: address.road || "",
            district: address.city || address.county || "",
            state: address.state || "",
            countryName: address.country || "",
            pincode: address.postcode || "",
          }));
        })
        .catch((error) => {
          console.error("Error fetching address details:", error);
        });

      setLocationChanged(true);
    }
  };

  const handleSubmit = async (e) => {
    if (!formData.queueName.trim()) {
      toast.error("Queue Name is required");
      return;
    }
    setLoading(true);
    e.preventDefault();

    const requiredFields = ["queueName", "geoLocation"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      toast.error(
        `Please fill in the following fields: ${missingFields.join(", ")}`
      );
      return;
    }
    const { availableFromTime, availableToTime } = formData;

    const businessStartTime = {
      hour: availableFromTime.split(":")[0],
      minute: availableFromTime.split(":")[1],
      nano: 1,
      second: "01",
    };

    const businessEndTime = {
      hour: availableToTime.split(":")[0],
      minute: availableToTime.split(":")[1],
      nano: 1,
      second: "01",
    };
    try {
      const companyId = localStorage.getItem("companyId");

      const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

      if (
        companyDetails &&
        Array.isArray(companyDetails.queues) &&
        companyDetails.queues.length > 0
      ) {
        const queueId = companyDetails.queues[0].queueId;

        const updateQueuePayload = {
          companyId: companyId,
          queueId: queueId,
          queueName: formData.queueName,
          queueLat: markerPosition[0],
          queueLon: markerPosition[1],
          businessStartTime,
          businessEndTime,
          qpincode: formData.pincode,
          qstreet: formData.streetName,
        };

        const workflowId = localStorage.getItem("workflowId");
        const updateQueueResponse = await axiosInstance.put(
          `${ApiRoutes.apiBaseUrl}/company/workflow/${workflowId}/queue`,
          updateQueuePayload
        );
        const updatedQueue = updateQueueResponse.data;

        toast.success("Queue details updated successfully!");
        setLoading(false);
        const updatedCompanyDetails = {
          ...companyDetails,
          queues: [
            {
              ...companyDetails.queues[0],
              queueName: formData.queueName,
              queueLat: markerPosition[0],
              queueLon: markerPosition[1],
              businessStartTime: updatedQueue.businessStartTime,
              businessEndTime: updatedQueue.businessEndTime,
              qpincode: updatedQueue.qpincode,
              qstreet: updatedQueue.qstreet,
            },
          ],
        };
        localStorage.setItem(
          "companyDetails",
          JSON.stringify(updatedCompanyDetails)
        );
      } else {
        const addQueuePayload = {
          companyId: companyId,
          queueName: formData.queueName,
          queueLat: markerPosition[0],
          queueLon: markerPosition[1],
          businessStartTime,
          businessEndTime,
          qpincode: formData.pincode,
          qstreet: formData.streetName,
        };

        const addQueueResponse = await axiosInstance.post(
          `${ApiRoutes.apiBaseUrl}/company/workflow/add-queue`,
          addQueuePayload
        );

        const workflowId = addQueueResponse.data;

        if (!workflowId) {
          console.error(
            "Failed to create queue for this company, contact administrator!"
          );
          return;
        }

        localStorage.setItem("workflowId", workflowId);

        const queueResponse = await axiosInstance.get(
          `${ApiRoutes.apiBaseUrl}/company/workflow/${workflowId}/queues`
        );
        const queueId = queueResponse.data[0].queueId;
        const generatedDetails = {
          ...companyDetails,
          queues: [
            {
              ...companyDetails.queues[0],
              queueName: formData.queueName,
              queueId: queueId,
              queueLat: markerPosition[0],
              queueLon: markerPosition[1],

              businessEndTime: queueResponse.businessEndTime,
              businessStartTime: queueResponse.businessStartTime,
              qstreet: queueResponse.qstreet,
              qpincode: queueResponse.qpincode,
            },
          ],
        };
        const queueCode = queueResponse.data[0].queueCode;
        localStorage.setItem(
          "companyDetails",
          JSON.stringify(generatedDetails)
        );
        const qrCodeUrl = process.env.REACT_APP_QRCODE_URL;
        console.log(qrCodeUrl);
        localStorage.setItem("queueCode", queueCode);
        const qrCodeData = `${qrCodeUrl}qcode=${queueCode}`;
        console.log(qrCodeData);

        const generateQrResponse = await axios.get(
          `${ApiRoutes.apiBaseUrl}${ApiRoutes.generateQrCode}`,
          {
            params: {
              data: qrCodeData,
            },
            responseType: "arraybuffer",
            headers: {
              Accept: "image/png",
            },
          }
        );

        const blob = new Blob([generateQrResponse.data], { type: "image/png" });
        const url = URL.createObjectURL(blob);
        localStorage.setItem("qrCodeUrl", url);

        navigate("/viewqr", { state: { qrCodeUrl: url } });
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const handlePincodeSearch = () => {
    if (formData.pincode) {
      axios
        .get(
          `https://nominatim.openstreetmap.org/search?postalcode=${formData.pincode}&country=${formData.countryName}&format=json`
        )
        .then((response) => {
          if (response.data.length > 0) {
            const lat = response.data[0].lat;
            const lon = response.data[0].lon;

            setMarkerPosition([parseFloat(lat), parseFloat(lon)]);
            setMapCenter([parseFloat(lat), parseFloat(lon)]);

            axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&addressdetails=1`
              )
              .then((response) => {
                const address = response.data.address;
                setFormData((prevData) => ({
                  ...prevData,
                  streetName: address.village || "",
                  district: address.state_district || "",
                  state: address.state || "",
                  countryName: address.country || "",
                  pincode: address.postcode || "",
                  geoLocation: `${lat}, ${lon}`,
                }));
              })
              .catch((error) => {
                console.error("Error fetching address details:", error);
              });
          } else {
            toast.error("No location found for the entered PIN code.");
          }
        })
        .catch((error) => {
          console.error("Error fetching location details:", error);
        });
    } else {
      toast.error("Please enter a PIN code to search.");
    }
  };

  const MapEventComponent = () => {
    useMapEvents({
      click: (e) => {
        handleMapSearch(e.latlng);
      },
    });

    return null;
  };

  const handleGeneralInfoClick = () => {
    // Handle click for General Info button
    setActiveSection("generalInfo");
  };

  const handleCountersClick = async () => {
    setActiveSection("counters");
    const companyDetailsString = localStorage.getItem("companyDetails");
    const companyDetail = JSON.parse(companyDetailsString);
    const qId = companyDetail.queues[0]?.queueId || 0;

    if (!qId) {
      toast.error("To open the counter, create the queue first");
      setLoading(false);
      setActiveSection("generalInfo");
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${ApiRoutes.apiBaseUrl}/token/get-active-tokens?qId=${qId}`
      );

      // Check if the response is successful (status code 200)
      if (response.status === 200) {
        let value = response.data;
        const Key = Object.keys(value)[2];
        const KeyValue = value[Key];
        setCounters(KeyValue);
        setCounterArray(KeyValue);
        setLoading(false);
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // const handleSlotsClick = () => {
  //   setActiveSection("slots");
  // };

  const showSlotPopup = () => {
    setAddSlotPopup(true);
  };

  const handlePopupClose = () => {
    setAddSlotPopup(false);
  };

  const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

  const businessStartTime =
    companyDetails?.queues[0]?.businessStartTime || "08:00";
  const businessEndTime = companyDetails?.queues[0]?.businessEndTime || "17:00";

  // Convert 24-hour format to 12-hour format with AM/PM indication
  const convertedStartTime = convertTo12HourFormat(businessStartTime);
  const convertedEndTime = convertTo12HourFormat(businessEndTime);

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    let formattedTime = "";

    let hour = parseInt(hours, 10);
    const amPm = hour >= 12 ? "PM" : "AM";

    if (hour === 0) {
      formattedTime += `12:${minutes} ${amPm}`;
    } else {
      hour = hour > 12 ? hour - 12 : hour;
      formattedTime += `${hour.toString().padStart(2, "0")}:${minutes} ${amPm}`;
    }

    return formattedTime;
  }

  // Counter

  const [counters, setCounters] = useState([]);
  const [editPopupVisible, setEditPopupVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [addNewPopupVisible, setAddNewPopupVisible] = useState(false);
  const [editedCounter, setEditedCounter] = useState({
    queueId: null,
    cid: null,
    cname: "",
    cno: 0,
    counterSlotDtoSet: [],
  });
  const [selectedCounterCid, setSelectedCounterCid] = useState(null);
  const [slotsDetails, setSlotsDetails] = useState([]);
  const [counterArray, setCounterArray] = useState(null);
  const [counterArrayNos, setCounterArrayNos] = useState("");
  // const [slots, setSelectedSlots] = useState([]);
  // const [showNoSlotMessage, setShowNoSlotMessage] = useState(false);

  const sortedCounters = counters.slice().sort((a, b) => a.cid - b.cid);
  // const sortedSlots = slotsDetails.sort((a, b) => {
  //   const timeA = new Date(`1970/01/01 ${a.slotStartTime}`);
  //   const timeB = new Date(`1970/01/01 ${b.slotStartTime}`);
  //   return timeA - timeB;
  // });

  const handleAddNewCounter = async () => {
    try {
      setAddNewPopupVisible(true);
      setLoading(true);
      const companyDetailsString = localStorage.getItem("companyDetails");
      const companyDetail = JSON.parse(companyDetailsString);
      const qId = companyDetail.queues[0]?.queueId || 0;

      if (!qId) {
        toast.error("QId not found in localStorage");
        return;
      }
      try {
        const response = await axiosInstance.get(
          `${ApiRoutes.apiBaseUrl}/token/get-active-tokens?qId=${qId}`
        );

        // Check if the response is successful (status code 200)
        if (response.status === 200) {
          let value = response.data;
          const Key = Object.keys(value)[2];
          const KeyValue = value[Key];
          setCounters(KeyValue);
          setCounterArray(KeyValue);
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }

      const cnoValues = counters.map((counter) => counter.cno);

      function findMissingNumbers(arr) {
        const max = Math.max(...arr);
        const min = Math.min(...arr);
        const allNumbers = Array.from(
          { length: max - min + 1 },
          (_, i) => i + min
        );
        return allNumbers.filter((num) => !arr.includes(num));
      }

      const missingCnoValues = findMissingNumbers(cnoValues);
      let counterNumberAssign;
      if (missingCnoValues.length === 0) {
        counterNumberAssign = counters.length + 1;
        setCounterArrayNos(counterNumberAssign);
      } else {
        counterNumberAssign = missingCnoValues[0];
        setCounterArrayNos(counterNumberAssign);
      }

      // Make an API call to get the slots details
      const response = await axiosInstance.get(
        `${ApiRoutes.apiBaseUrl}/company/workflow/get-slots/${qId}`
      );

      if (response.status === 200) {
        const slotsDetails = response.data;
        setSlotsDetails(slotsDetails);
        setLoading(false);
      } else {
        console.error("Failed to fetch slots details");
      }
    } catch (error) {
      console.error("Error during slots fetch:", error);
    }
  };

  const handleAddNewConfirm = async () => {
    const counterName = document.getElementById("counterName").value.trim();
    const companyDetailsString = localStorage.getItem("companyDetails");
    const companyDetail = JSON.parse(companyDetailsString);
    const qId = companyDetail.queues[0]?.queueId || 0;

    if (!counterName) {
      toast.error("Enter the counter name");
      return;
    }
    // Check if the counter name already exists in the counters state
    const isCounterExists = counters.some(
      (counter) => counter.cname === counterName
    );

    if (isCounterExists) {
      toast.error(`Counter name already exists.`);
      return;
    }
    if (counterName.length > 40) {
      toast.error("Counter name should not exceed 40 characters");
      return;
    }

    try {
      // if (slots.length === 0) {
      //   setShowNoSlotMessage(true);
      //   // Hide the message after 3 seconds
      //   setTimeout(() => {
      //     setShowNoSlotMessage(false);
      //   }, 3000);
      //   return;
      // }
      setLoading(true);
      const firstSlotId =
        slotsDetails.length > 0 ? slotsDetails[0].slotId : null;

      let payload;

      if (firstSlotId !== null) {
        payload = {
          cname: counterName,
          queueId: qId,
          slots: [firstSlotId],
        };
      } else {
        payload = {
          cname: counterName,
          queueId: qId,
        };
      }
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/workflow/${qId}/create-counter`,
        payload
      );
      setAddNewPopupVisible(false);
      setCounters(response.data);
      // handleCountersClick();
      setLoading(false);
      toast.success("Counter added successfully.");
    } catch (error) {
      console.error("Error creating counter:", error.message);
    }
  };

  const handleEdit = async (counterId) => {
    setEditPopupVisible(true);
    setLoading(true);
    const companyDetailsString = localStorage.getItem("companyDetails");
    const companyDetail = JSON.parse(companyDetailsString);
    const qId = companyDetail.queues[0]?.queueId || 0;

    const response = await axiosInstance.get(
      `${ApiRoutes.apiBaseUrl}/company/workflow/get-slots/${qId}`
    );

    if (response.status === 200) {
      // Assuming the server response contains slots details
      const slotsDetails = response.data;
      setSlotsDetails(slotsDetails);
      setLoading(false);
    } else {
      console.error("Failed to fetch slots details");
    }
    const selectedCounter = counters.find(
      (counter) => counter.cid === counterId
    );

    // Set the initial values for the input fields
    setEditedCounter({
      queueId: selectedCounter.queueId,
      cid: selectedCounter.cid,
      cname: selectedCounter.cname,
      cno: selectedCounter.cno,
      counterSlotDtoSet: selectedCounter.counterSlotDtoSet,
    });
  };

  const handleEditConfirm = async () => {
    const companyDetailsString = localStorage.getItem("companyDetails");
    const counterName = document.getElementById("counterName").value.trim();
    const companyDetail = JSON.parse(companyDetailsString);
    const qId = companyDetail.queues[0]?.queueId || 0;
    setLoading(true);

    // Check if the counter name is empty
    if (!counterName) {
      toast.error("Enter the counter name");
      return;
    }
    // Check if the user has selected any slots
    // if (editedCounter.counterSlotDtoSet.length === 0) {
    //   setShowNoSlotMessage(true);
    //   setTimeout(() => {
    //     setShowNoSlotMessage(false);
    //   }, 3000);
    //   return;
    // }

    const payload = {
      cid: editedCounter.cid,
      cname: editedCounter.cname,
      slots: editedCounter.counterSlotDtoSet.map((slot) => slot.slotId),
      queueId: qId,
    };

    try {
      // Make the API call to update the counter
      const response = await axiosInstance.put(
        `${ApiRoutes.apiBaseUrl}/company/workflow/${qId}/update-counter`,
        payload
      );
      if (response.status === 200) {
        toast.success("Counter updated successfully");
        setCounters(response.data);
        setEditPopupVisible(false);
        setLoading(false);
      } else {
        // Handle other response statuses if needed
        toast.error("Failed to update counter. Please try again.");
      }
    } catch (error) {
      console.error("Error updating counter:", error.message);
    }
    setEditPopupVisible(false);
  };

  const handleDelete = (counterCid) => {
    setDeleteConfirmationVisible(true);
    setSelectedCounterCid(counterCid);
  };

  const handleDeleteConfirm = async () => {
    try {
      // Find the queueId from the first element of the counters array
      const companyDetailsString = localStorage.getItem("companyDetails");
      const companyDetail = JSON.parse(companyDetailsString);
      const qId = companyDetail.queues[0]?.queueId || 0;
      const firstCounter = counters.length > 0 ? counters[0] : null;
      if (!firstCounter) {
        console.error("Unable to determine counter details for deletion");
        return;
      }
      const { queueId } = firstCounter;
      setLoading(true);
      await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/workflow/${queueId}/delete-counter`,
        { queueId, cid: selectedCounterCid }
      );
      setDeleteConfirmationVisible(false);
      try {
        const response = await axiosInstance.get(
          `${ApiRoutes.apiBaseUrl}/token/get-active-tokens?qId=${qId}`
        );

        // Check if the response is successful (status code 200)
        if (response.status === 200) {
          let value = response.data;
          const Key = Object.keys(value)[2];
          const KeyValue = value[Key];
          setCounters(KeyValue);
          setCounterArray(KeyValue);
          setLoading(false);
          toast.success("Counter deleted successfully");
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
      setLoading(false);
    } catch (error) {
      console.error(
        `Error during delete API call for counter with ID ${selectedCounterCid}:`,
        error
      );
      toast.error("Default counter can't be deleted.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditPopupVisible(false);
    setDeleteConfirmationVisible(false);
    setAddNewPopupVisible(false);
  };

  // const handleSlotCheckboxChange = (slotId) => {
  //   setSelectedSlots((prevSelectedSlots) => {
  //     const isSelected = prevSelectedSlots.includes(slotId);
  //     if (isSelected) {
  //       const updatedSlots = prevSelectedSlots.filter((id) => id !== slotId);
  //       return updatedSlots.length > 0 ? updatedSlots : null;
  //     } else {
  //       return [...prevSelectedSlots, slotId];
  //     }
  //   });
  // };

  // const handleSlotToggle = (slotId) => {
  //   setEditedCounter((prev) => {
  //     const isSlotSelected = prev.counterSlotDtoSet.some(
  //       (selectedSlot) => selectedSlot.slotId === slotId
  //     );

  //     if (isSlotSelected) {
  //       // Remove the slot if it was selected
  //       return {
  //         ...prev,
  //         counterSlotDtoSet: prev.counterSlotDtoSet.filter(
  //           (selectedSlot) => selectedSlot.slotId !== slotId
  //         ),
  //       };
  //     } else {
  //       // Add the slot if it was not selected
  //       return {
  //         ...prev,
  //         counterSlotDtoSet: [
  //           ...prev.counterSlotDtoSet,
  //           { slotId /* other slot properties */ },
  //         ],
  //       };
  //     }
  //   });
  // };
  return (
    <div className="generating-container">
      <Header />
      <NavBar />
      <div>
        {/* Loader */}
        {loading && (
          <div className="loader-overlay">
            <div className="loader-content">
              <div className="loader-spinner"></div>
              <p className="loading-text">Loading . . .</p>
            </div>
          </div>
        )}
        <div className="qrGenerate-title">
          <h3>
            {isExistingQueue ? "Update Queue" : "Create Queue"}
            <button
              className={
                activeSection === "generalInfo"
                  ? "active general-info"
                  : "general-info"
              }
              onClick={handleGeneralInfoClick}
            >
              General Info
            </button>
            <button
              className={
                activeSection === "counters" ? "active counters" : "counters"
              }
              onClick={handleCountersClick}
            >
              Counters
            </button>
          </h3>
        </div>
        <hr className="hr-generateQR" />
      </div>
      {activeSection === "slots" && (
        <div className="slote-container">
          <div className="slots-content">
            <div className="days-of-week-container">
              {Object.keys(daysOfWeek).map((day) => (
                <div key={day} className="day-box">
                  <div
                    className={`day-section ${day === "Sun" ? "sunday" : ""}`}
                  >
                    {day}
                  </div>
                  <Toggle
                    defaultChecked={daysOfWeek[day]}
                    onChange={() => handleToggle(day)}
                    className="custom-toggle-class"
                    icons={false}
                  />
                </div>
              ))}
            </div>
            <div>
              <h4>
                Working Hours
                <div className="working-hours">
                  {convertedStartTime} -{convertedEndTime}
                </div>
                <button className="addSlot-button" onClick={showSlotPopup}>
                  Add Slots
                </button>
              </h4>
            </div>
            <TimeSlotsCalendar key={isSlotAdded.toString()} />
            {addSlotPopup && (
              <div className="popup-overlay">
                <div className="popup-content scale-up">
                  {loading && (
                    <div className="loader-overlay">
                      <div className="loader-content">
                        <div className="loader-spinner"></div>
                        <p className="loading-text">Loading . . .</p>
                      </div>
                    </div>
                  )}
                  <div className="slot-form">
                    <h2>Add Slots</h2>
                    <div className="slotName-section">
                      <label className="slot-label">Slot Name </label>
                      <input
                        className="slot-input"
                        type="text"
                        id="slotName"
                        name="slotName"
                        placeholder="Enter slot name"
                      />
                    </div>
                    <div className="slotName-section">
                      <label className="time-section">
                        Type
                        <select
                          className="availabiity-dropdown"
                          id="slotAvailability"
                          name="slotAvailability"
                        >
                          <option value="available">Available</option>
                          <option value="unavailable">Unavailable</option>
                        </select>
                      </label>
                    </div>
                    <div className="slotTime-section">
                      <label className="time-section">
                        Time
                        <input
                          className="slot-time-section"
                          type="time"
                          id="startTime"
                          name="startTime"
                        />
                        <input
                          className="slot-time-section"
                          type="time"
                          id="endTime"
                          name="endTime"
                        />
                      </label>
                    </div>
                    <div></div>
                  </div>
                  <div className="update-btn-div1">
                    <button
                      className="close-button1"
                      onClick={handlePopupClose}
                    >
                      Cancel
                    </button>
                    <button className="close-button2" onClick={handleAddSlot}>
                      Add
                    </button>
                  </div>
                </div>
                <ToastContainer />
              </div>
            )}
          </div>
        </div>
      )}
      {activeSection === "counters" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div style={{ display: "flex" }}>
            <table
              style={{
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <thead style={{ backgroundColor: "black", color: "white" }}>
                <tr>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#3d4493",
                      textAlign: "center",
                      width: "200px",
                      borderRight: "1px solid white",
                    }}
                  >
                    Counter
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#3d4493",
                      textAlign: "center",
                      width: "200px",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedCounters.map((counter, index) => (
                  <tr key={counter.cid}>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {counter.cname}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <button
                        onClick={() => handleEdit(counter.cid)}
                        className="edit-icon-counter"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>

                      {/* Conditionally render the delete button */}
                      {index === 0 ? null : (
                        <button
                          onClick={() => handleDelete(counter.cid)}
                          className="delete-icon-counter"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Edit counter */}
            {editPopupVisible && (
              <div className="popup-overlay">
                <div className="popup-content">
                  {loading && (
                    <div className="loader-overlay">
                      <div className="loader-content">
                        <div className="loader-spinner"></div>
                        <p className="loading-text">Loading . . .</p>
                      </div>
                    </div>
                  )}
                  <h2 style={{ textAlign: "center" }}>Edit Counter</h2>
                  {/* {showNoSlotMessage && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginBottom: "1rem",
                        font: "normal bold 16px montserrat",
                      }}
                    >
                      Please select at least one time slot.
                    </div>
                  )} */}
                  <input
                    className="edit-input"
                    type="text"
                    id="counterName"
                    value={editedCounter.cname}
                    onChange={(e) =>
                      setEditedCounter((prev) => ({
                        ...prev,
                        cname: e.target.value,
                      }))
                    }
                  />
                  <br />
                  <input
                    className="edit-input"
                    type="text"
                    id="counterValue"
                    value={editedCounter.cno}
                    readOnly
                  />
                  <br />
                  {/* <div className="edit-popup-div">
                    <label className="select-label">Selected Time Slot</label>
                    {slotsDetails.map((slot) => (
                      <div
                        key={slot.slotId}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "1rem",
                        }}
                      >
                        <div style={{ display: "flex", width: "25%" }}>
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={editedCounter.counterSlotDtoSet.some(
                              (selectedSlot) =>
                                selectedSlot.slotId === slot.slotId
                            )}
                            onChange={() => handleSlotToggle(slot.slotId)}
                          />
                          <label className="edit-popup-label">
                            {slot.slotName}
                          </label>
                        </div>
                        <div>
                          <span>{slot.slotStartTime}</span>
                          <span style={{ marginLeft: "20px" }}>
                            {slot.slotEndTime}
                          </span>
                        </div>
                        <div>
                          <span>
                            {slot.slotAvailable ? "Available" : "Unavailable"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "15px 0pc",
                      gap: "10px",
                    }}
                  >
                    <button onClick={handleCancel} className="cancel-btn">
                      Cancel
                    </button>
                    <button onClick={handleEditConfirm} className="remove-btn">
                      Update Counter
                    </button>
                  </div>
                </div>
              </div>
            )}

            {deleteConfirmationVisible && (
              <div className="popup-overlay">
                <div className="popup-content">
                  {loading && (
                    <div className="loader-overlay">
                      <div className="loader-content">
                        <div className="loader-spinner"></div>
                        <p className="loading-text">Loading . . .</p>
                      </div>
                    </div>
                  )}
                  <h2>Are you sure?</h2>
                  <p className="delete-popup-para">
                    Do you wish to remove this counter? If so, please ensure
                    that all ongoing services are concluded before proceeding.
                  </p>
                  <button
                    onClick={handleCancel}
                    style={{ marginRight: "10px" }}
                    className="inform-btn"
                  >
                    Cancel
                  </button>
                  <button onClick={handleDeleteConfirm} className="remove-btn">
                    Delete Counter
                  </button>
                </div>
              </div>
            )}
            {addNewPopupVisible && (
              <div className="popup-overlay">
                <ToastContainer />
                <div className="popup-content">
                  {loading && (
                    <div className="loader-overlay">
                      <div className="loader-content">
                        <div className="loader-spinner"></div>
                        <p className="loading-text">Loading . . .</p>
                      </div>
                    </div>
                  )}
                  <h2 style={{ textAlign: "center" }}>Add New Counter</h2>
                  {/* Display message when no slots are selected */}
                  {/* {showNoSlotMessage && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginBottom: "1rem",
                        font: "normal bold 16px montserrat",
                      }}
                    >
                      Please select at least one time slot.
                    </div>
                  )} */}
                  <input
                    className="edit-input"
                    placeholder="Counter Name"
                    type="text"
                    id="counterName"
                  />
                  <br />
                  <input
                    className="edit-input"
                    placeholder="Counter Number"
                    type="text"
                    id="counterValue"
                    value={counterArrayNos}
                    readOnly
                  />
                  <br />
                  {/* <div className="edit-popup-div">
                    <label className="select-label">
                      Select Available Time Slot
                    </label>
                    {sortedSlots.map((slot) => (
                      <div
                        key={slot.slotId}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox"
                            onChange={() =>
                              handleSlotCheckboxChange(slot.slotId)
                            }
                          />
                          <label className="edit-popup-label">
                            {slot.slotName}
                          </label>
                        </div>
                        <div>
                          <span>{slot.slotStartTime}</span>
                          <span style={{ marginLeft: "20px" }}>
                            {slot.slotEndTime}
                          </span>
                        </div>
                        <div>
                          <span>
                            {slot.slotAvailable ? "Available" : "Unavailable"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "15px 0",
                      gap: "10px",
                    }}
                  >
                    <button onClick={handleCancel} className="cancel-btn">
                      Cancel
                    </button>
                    <button
                      onClick={handleAddNewConfirm}
                      className="remove-btn"
                    >
                      Add Counter
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <button
              onClick={handleAddNewCounter}
              className="remove-btn"
              style={{ marginTop: "22px" }}
            >
              Add New counter
            </button>
          </div>
        </div>
      )}

      {activeSection === "generalInfo" && (
        <div className="top-sectionQ">
          <div className="left-sectionQ">
            <label className="queue-label">Queue Name *</label>
            <input
              className="generator-input"
              type="text"
              name="queueName"
              value={formData.queueName}
              onChange={handleChange}
              required
            />

            <label className="queue-label">Geolocation *</label>
            <input
              className="generator-input"
              type="text"
              name="geoLocation"
              placeholder="mark location on the map"
              value={formData.geoLocation}
              onChange={handleChange}
              disabled
            />
            <label className="queue-label">Country</label>
            <input
              className="generator-input"
              type="text"
              name="countryName"
              value={formData.countryName}
              onChange={handleChange}
            />
            <label className="queue-label">Pincode/Zipcode</label>
            <div className="url-section">
              <input
                className="pincode-input"
                type="text"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
              />
              <img
                src={searchIcon}
                alt="Search"
                className="url-icon"
                onClick={handlePincodeSearch}
              ></img>
            </div>

            <label className="queue-label">Street name</label>
            <input
              className="generator-input"
              type="text"
              name="streetName"
              value={formData.streetName}
              onChange={handleChange}
            />
            <label className="queue-label">District</label>
            <input
              className="generator-input"
              type="text"
              name="district"
              value={formData.district}
              onChange={handleChange}
            />
            <label className="queue-label">State</label>
            <input
              className="generator-input"
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
            <div className="button-generating">
              {formEdited && (
                <button
                  className="generate-button"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {isExistingQueue ? "Update Queue" : "Save Queue"}
                </button>
              )}
            </div>
          </div>
          <div className="right-sectionQ">
            <div className="map-section">
              <MapContainer
                key={`${markerPosition?.[0]}-${markerPosition?.[1]}-${mapCenter[0]}-${mapCenter[1]}`}
                center={markerPosition || mapCenter}
                zoom={19}
                style={{
                  height: "405px",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <MapEventComponent />
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                {markerPosition && (
                  <Marker position={markerPosition} icon={customIcon}>
                    <Popup>
                      <div>
                        <h3>Selected Location</h3>
                        <p>Latitude: {markerPosition[0]}</p>
                        <p>Longitude: {markerPosition[1]}</p>
                      </div>
                    </Popup>
                  </Marker>
                )}
              </MapContainer>
            </div>
            {/* <div className="bottom-leftQ">
              <div className="available-time">
                <h2 className="working-hours-title">Working Hours</h2>
                <div className="time-section">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      border:'1px solid #e7e7ee',
                      padding:'10px',
                      alignItems:'center',
                      boxShadow:'rgba(0, 0, 0, 0.24) 0px 3px 8px'

                    }}
                  >
                    <label className=" time-label">From :</label>
                    <input
                      className="time-input"
                      type="time"
                      name="availableFromTime"
                      value={formData.availableFromTime}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems:'center',
                      border:'1px solid #e7e7ee',
                      padding:'10px',
                      boxShadow:'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    }}
                  >
                    <label className=" time-label">To : </label>
                    <input
                      className="time-input"
                      type="time"
                      name="availableToTime"
                      value={formData.availableToTime}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default QueueForm;
