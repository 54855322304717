import React from "react";
import { useNavigate } from "react-router-dom";
import ApiRoutes from "../ApiRoutes.js";
import axios from "axios";
import dashboard from "../Assets/dashboard.svg";
import company from "../Assets/company.svg";
import users from "../Assets/users.svg";
import update from "../Assets/update.svg";
import download from "../Assets/download.svg";
import "react-toastify/dist/ReactToastify.css";
import "../Components/navbar.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const NavBar = () => {
  const navigate = useNavigate();
  const [showUpdatePasswordDropdown, setShowUpdatePasswordDropdown] =
    useState(false);
  const [companyDetails, setCompanyDetails] = useState({});

  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    const companyDetailsString = localStorage.getItem("companyDetails");
    if (companyDetailsString) {
      const parsedCompanyDetails = JSON.parse(companyDetailsString);
      setCompanyDetails(parsedCompanyDetails);
    }
  }, []);

  const queueExists = companyDetails.queues && companyDetails.queues.length > 0;

  const handleNavigation = async (page) => {
    if (page === "viewqr") {
      try {
        const queueCode = localStorage.getItem("queueCode");
        if (queueCode) {
          const qrCodeUrl = process.env.REACT_APP_QRCODE_URL;
          const qrCodeData = `${qrCodeUrl}qcode=${queueCode}`;
          const generateQrResponse = await axios.get(
            `${ApiRoutes.apiBaseUrl}${ApiRoutes.generateQrCode}`,
            {
              params: {
                data: qrCodeData,
              },
              responseType: "arraybuffer",
              headers: {
                Accept: "image/png",
              },
            }
          );
          const blob = new Blob([generateQrResponse.data], {
            type: "image/png",
          });
          const url = URL.createObjectURL(blob);
          navigate("/viewqr", { state: { qrCodeUrl: url } });
        } else {
          console.error("Queue code not found in localStorage");
        }
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    } else if (page) {
      navigate(`/${page}`);
    }
  };

  const handleDownloadClick = () => {
    setShowUpdatePasswordDropdown((prevState) => !prevState);
  };

  const renderNavBarItems = () => {
    if (userRole === "counterUser") {
      return (
        <>
          <div
            className="sidebar-item"
            onClick={() => handleNavigation("viewque")}>
            <img src={dashboard} alt="Dashboard" className="navbar-icon" />
            <span>Dashboard</span>
          </div>

          <div
            className="sidebar-item settings-flex"
            onClick={handleDownloadClick}>
            <FontAwesomeIcon icon={faGear} className="setting-icon" />
            <span>Settings</span>
          </div>

          {showUpdatePasswordDropdown && (
            <div className="download-dropdown">
              <div onClick={() => handleNavigation("updatepassword")}>
                Update Password
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <div
            className="sidebar-item"
            onClick={() => handleNavigation("viewque")}>
            <img src={dashboard} alt="Dashboard" className="navbar-icon" />
            <span>Dashboard</span>
          </div>
          <div
            className="sidebar-item"
            onClick={() => handleNavigation("generateqr")}>
            <img src={update} alt="update" className="navbar-icon" />
            {queueExists ? "Update Queue" : "Create Queue"}
          </div>
          <div
            className="sidebar-item"
            onClick={() => handleNavigation("profile")}>
            <img src={company} alt="company-profile" className="navbar-icon" />
            Company Profile
          </div>
          <div
            className="sidebar-item"
            onClick={() => handleNavigation("users")}>
            <img
              src={users}
              alt="company-profile"
              className="navbar-icon users"
            />
            Users
          </div>
          <div
            className="sidebar-item"
            onClick={() => handleNavigation("viewqr")}>
            <img src={download} alt="download" className="navbar-icon" />
            Download QR
          </div>
          <div
            className="sidebar-item settings-flex"
            onClick={handleDownloadClick}>
            <FontAwesomeIcon icon={faGear} className="setting-icon" />
            <span>Settings</span>
          </div>

          {showUpdatePasswordDropdown && (
            <div className="download-dropdown">
              <div onClick={() => handleNavigation("updatepassword")}>
                Update Password
              </div>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-menu">{renderNavBarItems()}</div>
    </div>
  );
};

export default NavBar;
