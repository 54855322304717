import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage,deleteToken } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAa-hyvgqKOQp7V67bjymKIFU7axuhGwTA",
  authDomain: "queuedai-50191.firebaseapp.com",
  projectId: "queuedai-50191",
  storageBucket: "queuedai-50191.appspot.com",
  messagingSenderId: "106361479116",
  appId: "1:106361479116:web:dd59f41a88c6a24f2c8769",
  measurementId: "G-VCKB8T3Y9T"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
  console.log("Requesting User Permission");
  const isChrome = navigator.userAgent.includes("Chrome");
  const isFirefox = navigator.userAgent.includes("Firefox");

  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted");
      return getToken(messaging, {
        vapidKey:
          "BElUngCUcirQzpKXGpgy4KPUdFKfgxpiAJQe8xVGOMlv-2irqk30qP-MX0In-q7Mu6iRLY3Gms_BPTLXWqC_ClU",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Client Token:", currentToken);
            // localStorage.setItem("fcmToken", currentToken);
          } else {
            console.log("Failed to geerate the app registration token");
          }
        })
        .catch((err) => {
          console.log(
            "An Error occured while requesting to recieve the token",
            err
          );
        });
    } else {
      console.log("User Permission Denied");
      if (isChrome) {
        alert(
          "To make the most of your experience, this website requires notifications service to be turned on. Follow these simple steps:\n\n1. Click on the 🔒 icon located next to the URL in your browser.\n2. Locate 'Notifications' in the drop-down menu.\n3. Choose 'Allow' to ensure you receive timely updates."
        );
      } else if (isFirefox) {
        alert(
          "To make the most of your experience, this website requires notifications service to be turned on. Follow these simple steps:\n\n1. Click on the 🔒 icon located next to the URL in your browser.\n2. Locate 'Notifications' in the drop-down menu.\n3. Choose 'Allow' to ensure you receive timely updates. \n\u2022 Make sure to reload the page for changes to apply"
        );
      }
    }
  });
};

requestPermission();

export const detachFirebaseAndFCM = async () => {
  try {
  
    await deleteToken(messaging);
    console.log('FCM token deleted successfully');

    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
      await registration.unregister();
      console.log('Service worker unregistered.');
    }
  } catch (error) {
    console.error('Error deleting FCM token or unregistering service worker:', error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });