import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiRoutes from "../ApiRoutes.js";
import logo1921 from "../Assets/logo1921.png";
import axiosInstance from "../axiosInstance.js";
import "../Styles/Register.css";
import { getMessaging, getToken } from "firebase/messaging";

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    adminEmail: "",
    adminPassword: "",
    confirmPassword: "",
    businessSubtypeName: "Select Business type",
  });

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [businessSubtypes, setBusinessSubtypes] = useState([]);
  const [selectedBusinessSubtypeId, setSelectedBusinessSubtypeId] =
    useState("");
  useEffect(() => {
    axiosInstance
      .get("/company/business-subtypes")
      .then((response) => {
        const businessSubtypesData = response.data;
        setBusinessSubtypes(businessSubtypesData);
      })
      .catch((error) => {
        console.error("Error fetching business subtypes:", error);
      });
  }, []);

  const handleBlur = (e) => {
    if (e.target.name === "adminEmail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(formData.adminEmail);
      if (!isValidEmail) {
        toast.error("Please enter a valid email address");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "businessSubtypeName") {
      const selectedSubtype = businessSubtypes.find(
        (subtype) => subtype.businessSubTypeName === value
      );

      if (selectedSubtype) {
        setSelectedBusinessSubtypeId(selectedSubtype.businessSubTypeId);
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleVerifyEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.adminEmail);

    if (!isValidEmail) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      // Check if the email is already registered
      const checkEmailResponse = await axios.get(
        `${ApiRoutes.apiBaseUrl}/company/verify-email-registration/${formData.adminEmail}`
      );

      if (
        checkEmailResponse.status === 200 &&
        checkEmailResponse.data?.isRegistered
      ) {
        toast.error(
          "This email is already registered. Please use a different email."
        );
        return;
      }

      const messaging = getMessaging();
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BElUngCUcirQzpKXGpgy4KPUdFKfgxpiAJQe8xVGOMlv-2irqk30qP-MX0In-q7Mu6iRLY3Gms_BPTLXWqC_ClU",
      });

      const requestBody = {
        ...formData,
        fcmToken: currentToken,
      };
      const registerResponse = await axios.post(
        `${ApiRoutes.apiBaseUrl}${ApiRoutes.registerUser}`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (registerResponse.status === 200) {
        await axios.post(`${ApiRoutes.apiBaseUrl}/company/send-otp`, {
          adminEmail: formData.adminEmail,
          adminPassword: formData.adminPassword,
          companyName: formData.companyName,
        });
        setShowVerifyEmail(true);
        setShowOtpSection(true);
        toast.success("OTP sent to your email id. Please verify.");
      } else {
        toast.error("Registration failed. Please check your information.");
      }
    } catch (error) {
      toast.error("Email is already registered");
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const response = await axios.post(
        `${ApiRoutes.apiBaseUrl}/company/verify-otp`,
        {
          email: formData.adminEmail,
          otp: otpValue,
          verified: true,
        }
      );
      if (response.status === 200) {
        setIsVerified(true);
        toast.success("Verification successful");
        setShowOtpSection(false);
      } else {
        toast.error("Verification failed");
      }
    } catch (error) {
      toast.error("An error occurred during verification");
    }
  };

  const handleRegistration = async () => {
    if (!isVerified) {
      toast.error("Please verify your email before registering.");
      return;
    }

    if (formData.adminPassword !== formData.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    if (formData.businessSubtypeName === "Select Business type") {
      toast.error("Please select a business type.");
      return;
    }
    if (formData.companyName.trim() === "") {
      toast.error("Please enter a company name.");
      return;
    }
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    const isStrongPassword = passwordRegex.test(formData.adminPassword);

    if (!isStrongPassword) {
      toast.error(
        "Password is weak. Include a mix of characters, numbers, and symbols."
      );
      return;
    }

    try {
      const messaging = getMessaging();
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BElUngCUcirQzpKXGpgy4KPUdFKfgxpiAJQe8xVGOMlv-2irqk30qP-MX0In-q7Mu6iRLY3Gms_BPTLXWqC_ClU",
      });

      const requestBody = {
        ...formData,
        businessSubTypeId: selectedBusinessSubtypeId,
        fcmToken: currentToken,
      };
      const response = await axios.post(
        `${ApiRoutes.apiBaseUrl}${ApiRoutes.registerUser}`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let jwtToken = response.data;
      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem("userRole", "companyAdmin");
      // let companyUserId = response.data.companyUserId;
      // localStorage.setItem("companyUserId", companyUserId);

      const companyResponse = await axiosInstance.get(
        `${ApiRoutes.apiBaseUrl}/company/0`
      );
      const { companyId: fetchedCompanyId } = companyResponse.data;

      if (fetchedCompanyId !== undefined && fetchedCompanyId !== null) {
        localStorage.setItem("companyId", fetchedCompanyId);
      }

      const { queues } = companyResponse.data;
      if (queues && queues.length > 0) {
        const queueCode = queues[0].queueCode;
        localStorage.setItem("queueCode", queueCode);
      }
      localStorage.setItem(
        "companyDetails",
        JSON.stringify(companyResponse.data)
      );

      if (response.status === 200) {
        toast.success("Registration successful!", {
          onClose: () => {
            setTimeout(() => {
              navigate("/generateqr");
            }, 2000);
          },
        });
      } else {
        toast.error("Registration failed. Please check your information.");
      }
    } catch (error) {
      toast.error("An error occurred during registration.");
    }
  };

  return (
    <div className="main-container">
      <div className="register-container">
        <div className="register-content">
          <div className="welcome">
            <img className="header-image" src={logo1921} alt="Your Logo" />
          </div>
          <div className="register-form">
            <label className="register-label">Company Name</label>
            <input
              className="register-input"
              type="text"
              name="companyName"
              placeholder="Company name"
              onChange={handleChange}
            />
          </div>
          <div className="register-form">
            <label className="register-label">Business type</label>
            <select
              className="register-input1"
              name="businessSubtypeName"
              value={formData.businessSubtypeName}
              onChange={handleChange}
            >
              <option disabled value="Select Business type">
                Select Business type
              </option>
              {businessSubtypes &&
                businessSubtypes.map((subtype) => (
                  <option
                    key={subtype.businessSubTypeId}
                    value={subtype.businessSubTypeName}
                  >
                    {subtype.businessSubTypeName}
                  </option>
                ))}
            </select>
          </div>
          <div className="register-form">
            <label className="register-label">Email</label>
            <input
              className="register-input"
              type="email"
              name="adminEmail"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className="tick-container">
              {isVerified && (
                <div className="tick-mark" style={{ color: "green" }}>
                  &#10004;
                </div>
              )}
              {!showVerifyEmail && (
                <button className="verify-button" onClick={handleVerifyEmail}>
                  Verify
                </button>
              )}
            </div>
            {!isVerified && (
              <div className="verify-text">verify email before registering</div>
            )}
            {showVerifyEmail && (
              <div
                className="otp-section"
                style={{ display: showOtpSection ? "block" : "none" }}
              >
                <label className="register-label">OTP</label>
                <input
                  className="register-input"
                  type="text"
                  placeholder="Enter OTP"
                  value={otpValue}
                  onChange={(e) => setOtpValue(e.target.value)}
                />
                <button className="submit-otp-button" onClick={handleOtpSubmit}>
                  Submit
                </button>
              </div>
            )}
          </div>
          <div className="register-form">
            <label className="register-label">Password</label>
            <input
              className="register-input"
              type="password"
              name="adminPassword"
              placeholder="Password"
              onChange={handleChange}
            />
          </div>
          <div className="register-form">
            <label className="register-label">Confirm Password</label>
            <input
              className="register-input"
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={handleChange}
            />
          </div>
          <div className="registerButton-section">
            <button
              type="submit"
              className="register-button"
              onClick={handleRegistration}
            >
              Register
            </button>
            <button className="cancel-button" onClick={() => navigate("/")}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Register;
