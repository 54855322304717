import React, { useState } from "react";
import Logo from "../Assets/Logo.png";
import { handleLogout } from "../utils/index.js";
import { useNavigate } from "react-router-dom";
import logout from "../Assets/logout.svg";
import axiosInstance from "../axiosInstance.js";
import { detachFirebaseAndFCM } from "../firebase.js";
import ApiRoutes from "../ApiRoutes.js";
import { toast } from "react-toastify";

const Header = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleUserLogout = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/company-user-log-off`
      );

      if (response.status === 200) {
        await detachFirebaseAndFCM();
        handleLogout();
        navigate("/");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Please ensure that inprogress tokens are completed.");
      } else {
        console.error("Error occurred. User not logged out.", error);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={headerStyle}>
        <img src={Logo} alt="Logo" style={logoStyle} />

        <button
          onClick={() => {
            handleUserLogout();
          }}
          style={logoutStyle}
          className={loading ? "loading" : ""}
        >
          <img
            src={logout}
            alt="SG Icon"
            style={{ width: "20px", height: "20px" }}
          />
          {loading && (
            <div className="loader-content-logout">
              <div className="loader-spinner-logout"></div>
            </div>
          )}
          {!loading && (
            <span style={{ font: "normal bold 13px montserrat" }}>Logout</span>
          )}
        </button>
      </div>
    </div>
  );
};

const headerStyle = {
  position: "fixed",
  display: "flex",
  width: "100%",
  height: "40px",
  top: "0px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  flexShrink: 0,
  background: "#EEF0FF",
  backdropFilter: "blur(20px)",
  zIndex: 1,
};

const logoStyle = {
  width: "150px",
  height: "35px",
  paddingLeft: "72px",
};

const logoutStyle = {
  display: "flex",
  width: "100px",
  height: "35px",
  padding: "10px",
  fontFamily: "Montserrat",
  margin: "21px 45px 18px 0",
  justifyContent: "center",
  alignItems: "center",
  background: "#eef0ff00",
  gap: "8px",
  borderRadius: "8px",
  border: "2px solid #3d4493",
  cursor: "pointer",
};

export default Header;
