const apiUrl = process.env.REACT_APP_API_BASE_URL;
console.log("API URL:", apiUrl);

const ApiRoutes = {
  apiBaseUrl: apiUrl,
  registerUser: "/company/register",
  loginUser: "/company/login",
  generateQrCode: "/company/generate-qr-code",
};

export default ApiRoutes;
