import React, { useEffect, useState } from "react";
import "../Styles/ViewQueue.css";
import NavBar from "../Components/Navbar.js";
import ApiRoutes from "../ApiRoutes.js";
import Header from "../Components/Header.js";
import axiosInstance from "../axiosInstance.js";
import userIcon from "../Assets/userIcon.svg";
import { onMessageListener } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const TokenStatus = {
  WAITING: 0,
  CALLING: 1,
  NO_SHOW: 2,
  IN_PROGRESS: 3,
  CANCEL: 4,
  COMPLETE: 5,
};

function ViewQueue() {
  const [queues, setQueues] = useState([]);
  const [tokenDetails, setTokenDetails] = useState([]);
  const [activeTokenIndex, setActiveTokenIndex] = useState(0);
  const [buttonTextIndex, setButtonTextIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [userName, setuserName] = useState("");
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("#F2994A");
  const [queueAvgWait, setQueueAvgWait] = useState(0);
  const [emailField, setemailField] = useState("");
  const [tokenNumber, setTokenNumber] = useState();
  const [mobileNumber, setMobileNumber] = useState("");
  const [showTokenNumberPopup, setshowTokenNumberPopup] = useState(false);
  const [showManualTokenPopup, setshowManualTokenPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [shouldReloadData, setShouldReloadData] = useState(false);
  const [accompanyMembers, setAccompanyMembers] = useState(false);
  const [accompanyingMembers, setAccompanyingMembers] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const [timerShowPopup, setTimerShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTokenArray, setActiveTokenArray] = useState();
  const [waitPopupToken, setWaitPopupToken] = useState();
  const [showSection, setShowSection] = useState(false);
  // const [nextLine, setNextLine] = useState(false);
  // const [savedValue, setSavedValue] = useState(true);
  // const [isChecked, setIsChecked] = useState(false);
  // const [isAutoCallVisible, setIsAutoCallVisible] = useState(false);

  // useEffect(() => {
  //   getAllCounterUser();
  // }, []);

  // useEffect(() => {
  //   if (buttonBackgroundColor === "#F2994A") {
  //     setIsAutoCallVisible(false);
  //   } else {
  //     setIsAutoCallVisible(true);
  //   }
  // }, [buttonBackgroundColor]);

  useEffect(() => {
    const storedCompanyDetails = localStorage.getItem("companyDetails");
    if (storedCompanyDetails) {
      const companyDetails = JSON.parse(storedCompanyDetails);
      const queuesData = companyDetails.queues || [];
      setQueues(queuesData);

      const businessSubTypeId =
        companyDetails.businessSubtype.businessSubTypeId;
      if (businessSubTypeId === null || businessSubTypeId === undefined) {
        return;
      }
      if (businessSubTypeId === 1 || businessSubTypeId === 2) {
        setAccompanyMembers(true);
      } else {
        setAccompanyMembers(false);
      }

      if (!shouldReloadData) {
        const existingQueueId = queuesData[0]?.queueId;
        if (existingQueueId) {
          fetchTokenDetails(existingQueueId).then(() => {
            const activeToken = tokenDetails.find(
              (token) => token.tokenStatus !== TokenStatus.COMPLETE
            );
            const activeTokenIndex = activeToken
              ? tokenDetails.findIndex(
                  (token) => token.tokenId === activeToken.tokenId
                )
              : 0;
            setActiveTokenIndex(activeTokenIndex);
          });
        }
      }
    }

    if (!shouldReloadData) {
      setShouldReloadData(true);
    }
  }, [shouldReloadData]);

  useEffect(() => {
    if (tokenDetails.length > 0) {
      const counterno = parseInt(localStorage.getItem("cno"));
      const currentToken = tokenDetails.find(
        (token) =>
          token.tokenCounterNo === counterno ||
          token.tokenCounterNo === null ||
          token.tokenCounterNo === ""
      );

      if (currentToken) {
        if (currentToken.tokenStatus === TokenStatus.CALLING) {
          setButtonTextIndex(0);
          setButtonBackgroundColor("#27AE60");
        } else if (currentToken.tokenStatus === TokenStatus.IN_PROGRESS) {
          setButtonTextIndex(0);
          setButtonBackgroundColor("#27AE60");
        } else {
          setButtonTextIndex(0);
          setButtonBackgroundColor("#F2994A");
        }
      } else {
        setButtonTextIndex(0);
        setButtonBackgroundColor("#F2994A");
      }
    } else {
      setButtonTextIndex(0);
      setButtonBackgroundColor("#F2994A");
    }
    setupMessageListener();
  }, [tokenDetails, buttonTextIndex]);

  // const userRole = localStorage.getItem("userRole");
  const transformNotificationData = (notificationData) => {
    return {
      tokenId: parseInt(notificationData.tId),
      tokenNumber: parseInt(notificationData.tNo),
      createdAt: new Date(),
      lastModifiedAt: new Date(),
      tokenETA: parseInt(notificationData.avgWaiting),
      tokenUser: parseInt(notificationData.tUser),
      tokenQueue: parseInt(notificationData.queueId),
      tokenStatus: parseInt(notificationData.tStatus),
      tokenCalls: parseInt(notificationData.activeTokens),
      tokenCounterNo: isNaN(parseInt(notificationData.tCounterNo))
        ? ""
        : parseInt(notificationData.tCounterNo),
    };
  };

  const setupMessageListener = () => {
    onMessageListener().then((payload) => {
      const transformedData = transformNotificationData(payload.data);
      console.log("token from notification", transformedData);
      setTokenDetails((prevTokenDetails) => {
        const isTokenInState = prevTokenDetails.some(
          (token) => token.tokenId === transformedData.tokenId
        );
        console.log("token", isTokenInState);
        if (
          transformedData.tokenStatus === 5 ||
          transformedData.tokenStatus === 4
        ) {
          const updatedTokenDetails = prevTokenDetails.filter(
            (token) => token.tokenId !== transformedData.tokenId
          );
          console.log("updated details1", updatedTokenDetails);
          return updatedTokenDetails;
        }
        if (isTokenInState) {
          const updatedTokenDetails = prevTokenDetails.map((token) =>
            token.tokenId === transformedData.tokenId ? transformedData : token
          );
          console.log("updated details", updatedTokenDetails);
          return updatedTokenDetails;
        } else {
          return [...prevTokenDetails, transformedData];
        }
      });
      setLoading(false);

      toast.info("Token updated", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  const fetchTokenDetails = async (queueId) => {
    try {
      setLoading(true);

      const response = await axiosInstance.get(
        `${ApiRoutes.apiBaseUrl}/token/get-active-tokens?qId=${queueId}`
      );

      const { activeTokens, queueAvgWait, scounters } = response.data;

      activeTokens.sort((a, b) => {
        if (a.tokenStatus === TokenStatus.NO_SHOW) return 1;
        if (b.tokenStatus === TokenStatus.NO_SHOW) return -1;
        return a.tokenId - b.tokenId;
      });

      setActiveTokenArray(activeTokens);
      const userRole = localStorage.getItem("userRole");
      let cid;
      let cno;
      let cname;

      if (userRole === "counterUser") {
        setShowSection(true);
        // Use usercid from localStorage when the user role is Counter User
        cid = localStorage.getItem("usercid");
        cno = localStorage.getItem("cno");
        cname = localStorage.getItem("cname");
      } else {
        // Check if there is a counter with cno equal to 1
        const counterWithCno1 = scounters.find((counter) => counter.cno === 1);

        if (counterWithCno1) {
          // Use the cid associated with the counter with cno equal to 1
          cid = counterWithCno1.cid;
          cno = counterWithCno1.cno;
          cname = counterWithCno1.cname;
        } else {
          // Fall back to the first counter's cid if not found
          cid = scounters.length > 0 ? scounters[0].cid : null;
          cno = scounters.length > 0 ? scounters[0].cno : null;
          cname = scounters.length > 0 ? scounters[0].cname : null;
        }
      }

      localStorage.setItem("cid", cid);
      localStorage.setItem("cno", cno);
      localStorage.setItem("cname", cname);
      getAllCounterUser();
      setTokenDetails(activeTokens);
      setQueueAvgWait(queueAvgWait);
    } catch (error) {
      console.error("Error fetching token details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to update the state and set the saved value
  // const handleCheckboxChange = () => {
  //   const newValue = !isChecked;
  //   setIsChecked(newValue);
  //   setSavedValue(newValue);

  //   // Use newValue directly instead of isChecked
  //   if (newValue === true) {
  //     setButtonTextIndex(0);
  //     console.log("Need to change", buttonTextIndex);
  //   } else {
  //     setButtonTextIndex(2);
  //     console.log("No need to changes", buttonTextIndex);
  //   }
  // };

  const mapTokenStatus = (statusNumber) => {
    switch (statusNumber) {
      case TokenStatus.WAITING:
        return "Waiting";
      case TokenStatus.CALLING:
        return "Calling";
      case TokenStatus.NO_SHOW:
        return "No Show";
      case TokenStatus.IN_PROGRESS:
        return "In Progress";
      case TokenStatus.CANCEL:
        return "Cancelled";
      case TokenStatus.COMPLETE:
        return "Complete";
      default:
        return "Unknown";
    }
  };
  const updateTokenStatus = async (currentToken) => {
    try {
      setLoading(true);
      const cid = localStorage.getItem("cid");
      const tokenUpdateData = {
        tokenFrom: "Company",
        tokenId: currentToken.tokenId,
        tokenStatus: currentToken.tokenStatus,
        counterId: cid,
      };

      const response = await axiosInstance.put(
        `${ApiRoutes.apiBaseUrl}/token/update-token-status`,
        tokenUpdateData
      );

      if (response.status === 200) {
        const updatedTokenDetails = [...tokenDetails];
        const tokenIndex = updatedTokenDetails.findIndex(
          (token) => token.tokenId === currentToken.tokenId
        );

        // if (tokenIndex !== -1) {
        //   updatedTokenDetails[tokenIndex].tokenCalls = response.data.tokenCalls;
        //   setTokenDetails(updatedTokenDetails);
        // }
        // setTokenDetails((tokens) => {
        //   return tokens.map((token) =>
        //     token.tokenNumber === response.data.tokenNumber
        //       ? {
        //           ...token,
        //           tokenStatus: response.data.tokenStatus,
        //           tokenCounterNo: response.data.tokenCounterNo,
        //         }
        //       : token
        //   );
        // });
        return response.data;
      } else {
        console.error("Failed to update token status.");
      }
    } catch (error) {
      console.error("Error updating token status:", error);
    }
  };

  const handleCancelButtonClick = async () => {
    try {
      const updatedTokenDetails = [...tokenDetails];
      const counterno = parseInt(localStorage.getItem("cno"));

      const currentToken = updatedTokenDetails.find(
        (token) =>
          token.tokenCounterNo === counterno ||
          token.tokenCounterNo === null ||
          token.tokenCounterNo === ""
      );

      if (currentToken) {
        if (
          currentToken.tokenStatus === TokenStatus.CALLING ||
          currentToken.tokenStatus === TokenStatus.IN_PROGRESS
        ) {
          if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
          }
          setButtonTextIndex(0);
          setButtonBackgroundColor("#F2994A");

          const updatedToken = {
            ...currentToken,
            tokenStatus: TokenStatus.CANCEL,
          };
          const isUpdated = await updateTokenStatus(updatedToken);

          if (isUpdated) {
            const updatedTokenDetails = tokenDetails
              .filter((token) => token.tokenId !== updatedToken.tokenId)
              .map((token) => ({ ...token }));

            // setTokenDetails(updatedTokenDetails);

            setQueues((prevQueues) =>
              prevQueues.map((queue) =>
                queue.queueId === updatedToken.queueId
                  ? {
                      ...queue,
                      queueActiveTokens:
                        queue.queueActiveTokens > 0
                          ? queue.queueActiveTokens - 1
                          : 0,
                    }
                  : queue
              )
            );
            // setNextLine(true);

            toast.success("Token successfully canceled.");
          } else {
            toast.error("Failed to cancel token.");
          }
        } else if (currentToken.tokenStatus === TokenStatus.WAITING) {
          toast.error("Cannot cancel a waiting token.");
        } else {
        }
      }
    } catch (error) {
      console.error("Error canceling token:", error);
      toast.error("No active token found.");
    } finally {
      setLoading(false);
    }
  };

  const handleManualTokenClick = () => {
    setshowManualTokenPopup(true);
  };

  const handlePopupClose = () => {
    setshowTokenNumberPopup(false);
    setshowManualTokenPopup(false);
  };

  const changeTokenToInProgress = async (recentToken) => {
    const tokenUpdateData = {
      tokenFrom: "Company",
      tokenId: recentToken.tokenId,
      tokenStatus: TokenStatus.IN_PROGRESS,
    };
    //Check tokenDetails still the tokenId present or not, before calling the update API. //Intuition there is chance admin can maually cancel the token.
    const tokens = [...tokenDetails];
    console.log(tokens, "Including");
    if (tokens.includes(recentToken)) {
      const isUpdated = await updateTokenStatus(tokenUpdateData);
    }
  };

  const handleNextButtonClick = async () => {
    try {
      // await fetchTokenDetails(tokenDetails[0].tokenQueue);
      // setNextLine(false);
      const updatedTokenDetails = [...tokenDetails];

      const counterno = parseInt(localStorage.getItem("cno"));

      const currentToken = updatedTokenDetails.find(
        (token) =>
          token.tokenCounterNo === counterno ||
          token.tokenCounterNo === null ||
          token.tokenCounterNo === ""
      );

      if (currentToken) {
        let buttonTextIndexToUpdate = buttonTextIndex;

        if (currentToken.tokenStatus === TokenStatus.WAITING) {
          const tokenUpdateData = {
            tokenFrom: "Company",
            tokenId: currentToken.tokenId,
            tokenStatus: TokenStatus.CALLING,
          };

          try {
            const isUpdated = await updateTokenStatus(tokenUpdateData);

            if (isUpdated) {
              currentToken.tokenStatus = TokenStatus.CALLING;
              setButtonBackgroundColor("#27AE60");
              buttonTextIndexToUpdate = 0;
              let latestCallingToken = currentToken;
              // automatic calling to In-progress state after 1 min when token is called.
              const timerId = setTimeout(() => {
                changeTokenToInProgress(latestCallingToken);
              }, 60000);
              setWaitPopupToken(currentToken.tokenNumber);

              setTimerId(timerId);
              // setIsAutoCallVisible(true);
              // setIsChecked(true);
              // setSavedValue(true);
              // await fetchTokenDetails(currentToken.tokenQueue);
            } else {
              console.error("Failed to update token status.");
              toast.error("Token update has an error.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } catch (error) {
            console.error("Error updating token status:", error);
          }
        } else if (currentToken.tokenStatus === TokenStatus.CALLING) {
          // Similar logic for CALLING to IN_PROGRESS transition
          // Here we are changing the status from calling to complete. // To handle user call next clicks before 60s.
          const tokenUpdateData = {
            tokenFrom: "Company",
            tokenId: currentToken.tokenId,
            tokenStatus: TokenStatus.COMPLETE,
          };
          // setIsAutoCallVisible(true);
          try {
            const isUpdated = await updateTokenStatus(tokenUpdateData);
            if (isUpdated) {
              currentToken.tokenStatus = TokenStatus.COMPLETE;
              buttonTextIndexToUpdate = 0;
              let latestTokens = [...tokenDetails];
              for (let i = 0; i < latestTokens.length; i++) {
                if (
                  latestTokens[i].tokenId === isUpdated.tokenId ||
                  latestTokens[i].tokenStatus === TokenStatus.COMPLETE
                ) {
                  latestTokens.splice(i, 1);
                }
              }
              // setTokenDetails(latestTokens);
              clearTimeout(timerId);
              // setIsAutoCallVisible(true);
              // await fetchTokenDetails(currentToken.tokenQueue);
            } else {
              console.error("Failed to update token status.");
              toast.error("Token update has an error.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } catch (error) {
            console.error("Error updating token status:", error);
          }
        } else if (currentToken.tokenStatus === TokenStatus.IN_PROGRESS) {
          // Similar logic for IN_PROGRESS to COMPLETE transition
          const tokenUpdateData = {
            tokenFrom: "Company",
            tokenId: currentToken.tokenId,
            tokenStatus: TokenStatus.COMPLETE,
          };

          try {
            const isUpdated = await updateTokenStatus(tokenUpdateData);

            if (isUpdated) {
              // currentToken.tokenStatus = TokenStatus.COMPLETE;
              setButtonBackgroundColor("#F2994A");
              buttonTextIndexToUpdate = 2;
              // setTokenDetails((token) => {
              //   return token.filter(
              //     (token) => token.tokenStatus !== TokenStatus.COMPLETE
              //   );
              // });
              // setIsAutoCallVisible(false);
              clearTimeout(timerId);
              // await fetchTokenDetails(currentToken.tokenQueue);

              // Check userRole before setting NextLine value
              // if (userRole === "companyAdmin") {
              //   setNextLine(true);
              // } else {
              //   setNextLine(savedValue);
              // }
            } else {
              console.error("Failed to update token status.");
              toast.error("Token update has an error.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } catch (error) {
            console.error("Error updating token status:", error);
          }
        } else if (currentToken.tokenStatus === TokenStatus.NO_SHOW) {
          // Similar logic for NO_SHOW to CALLING transition
          const tokenUpdateData = {
            tokenFrom: "Company",
            tokenId: currentToken.tokenId,
            tokenStatus: TokenStatus.CALLING,
          };

          try {
            const isUpdated = await updateTokenStatus(tokenUpdateData);

            if (isUpdated) {
              currentToken.tokenStatus = TokenStatus.CALLING;
              setButtonBackgroundColor("#27AE60");
              buttonTextIndexToUpdate = 0;
              const timerId = setTimeout(() => {
                setTimerShowPopup(true);
              }, 60000);

              setTimerId(timerId);
              await fetchTokenDetails(currentToken.tokenQueue);
            } else {
              console.error("Failed to update token status.");
              toast.error("Token update has an error.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } catch (error) {
            console.error("Error updating token status:", error);
          }
        }

        setButtonTextIndex(buttonTextIndexToUpdate);
      } else {
        // toast.error("Token already assigned to another counter");
      }
    } catch (error) {
    } finally {
      // setLoading(false);
    }
  };
  const buttonTexts = ["Call Next", "Attend", "Complete"];
  const cancelText = "Cancel";

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const getStatusTextColor = (statusNumber) => {
    switch (statusNumber) {
      case 0:
        return "#F2994A"; // Waiting
      case 1:
        return "#2D9CDB"; // Calling
      case 2:
        return "#4F4F4F"; // No Show
      case 3:
        return "#D1A001"; // In Progress
      case 4:
        return "#fd0e0e"; // Cancel
      case 5:
        return "#27AE60"; // Complete
      default:
        return "black"; // Unknown status
    }
  };
  const countActiveTokens = (tokenList) => {
    return tokenList.filter(
      (token) =>
        token.tokenStatus !== TokenStatus.COMPLETE &&
        token.tokenStatus !== TokenStatus.CANCEL
    ).length;
  };

  const handleCloseCounter = () => {
    setShowPopup(false);

    setShowSecondPopup(true);
  };

  const handleOpenCounter = () => {
    setShowSecondPopup(false);
  };

  const getAllCounterUser = async () => {
    try {
      const userRole = localStorage.getItem("userRole");

      if (userRole === "companyAdmin") {
        const response = await axiosInstance.get(
          `${ApiRoutes.apiBaseUrl}/company/counter-user-check`
        );

        if (response) {
          if (response.data === false) {
            setShowSection(true);
          }
        } else {
          console.error("Error in API response:", response);
          return null;
        }
      } else {
      }
    } catch (error) {
      console.error("Error while calling the API:", error);
      return null;
    }
  };

  const handleManualTokenSubmit = async () => {
    try {
      const companyDetailsString = localStorage.getItem("companyDetails");
      setLoading(true);
      if (!mobileNumber || !/^\d{10,15}$/.test(mobileNumber)) {
        toast.error("Enter a valid mobile number with 10 to 15 digits!");
        setLoading(false);
        return;
      }

      const validateEmail = (email) => {
        return !email || /^\S+@\S+\.\S+$/.test(email);
      };

      if (!validateEmail(emailField)) {
        if (emailField) {
          toast.error("Enter a valid email address!");
          setLoading(false);
          return;
        }
      }
      if (companyDetailsString) {
        const companyDetails = JSON.parse(companyDetailsString);
        const selectedQueueId = companyDetails.queues[0]?.queueId || 0;

        const payload = {
          email: emailField,
          phoneNo: mobileNumber,
          queueId: selectedQueueId,
          userName: userName,
        };

        const tokenResponse = await axiosInstance.post(
          "/token/manual-token",
          payload
        );

        if (tokenResponse.status === 200) {
          const newTokenNumber = tokenResponse.data.tokenNumber;

          const isTokenActive = activeTokenArray.some(
            (token) => token.tokenNumber === newTokenNumber
          );

          if (isTokenActive) {
            toast.error("Mobile number already submitted with token");
            // setshowManualTokenPopup(false);
          } else {
            toast.success("Token submitted successfully");
            // setTokenDetails([...tokenDetails, tokenResponse.data]);
            setshowTokenNumberPopup(true);
            setshowManualTokenPopup(false);

            if (newTokenNumber) {
              setTokenNumber(newTokenNumber);
            }
          }
        } else {
          console.error("Failed to submit token", tokenResponse.status);
        }
      } else {
        console.error("No company details found in local storage");
      }
    } catch (error) {
      console.error("Error submitting token:", error);
      toast.error("Token already generated for the given mobile number.");
    }
    setLoading(false);
  };

  const handleClosePopup = () => {
    setTimerShowPopup(false);
  };

  const navigate = useNavigate();
  const handleCreateQueue = () => {
    navigate("/generateqr");
  };

  const getTokenInfoToShow = (infoType) => {
    const counterno = parseInt(localStorage.getItem("cno"));
    const firstMatchingToken = tokenDetails.find(
      (token) =>
        token.tokenCounterNo === counterno || token.tokenCounterNo === null
    );

    switch (infoType) {
      case "number":
        return firstMatchingToken?.tokenNumber || "--";
      case "calls":
        return firstMatchingToken?.tokenCalls || "--";
      default:
        return "--";
    }
  };

  const getAdminEmailFromLocalStorage = () => {
    try {
      const userRole = localStorage.getItem("userRole");

      if (userRole === "companyAdmin") {
        // Parse the companyDetails string to an object
        const companyDetailsString = localStorage.getItem("companyDetails");
        const companyDetails = JSON.parse(companyDetailsString);

        // Access the nested adminEmail property
        const adminEmail = companyDetails ? companyDetails.adminEmail : null;

        return adminEmail || "";
      } else if (userRole === "counterUser") {
        const counterUserEmail = localStorage.getItem("userEmail");
        return counterUserEmail || "";
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  };

  return (
    <div className="que-container">
      <Header />
      <NavBar />
      <div>
        {/* Loader */}
        {loading && (
          <div className="loader-overlay">
            <div className="loader-content">
              <div className="loader-spinner"></div>
              {/* <p className="loading-text">Loading . . .</p> */}
            </div>
          </div>
        )}
        {queues.length === 0 && (
          <div className="popup-overlay">
            <div className="popup-content scale-up">
              <h2 className="popup-title">Create a queue</h2>
              <p className="popup-para">
                To proceed, you need to create a queue. Please create a new
                queue
              </p>
              <div className="button-flex">
                <button
                  className="create-queue-button"
                  onClick={handleCreateQueue}
                >
                  Create Queue
                </button>
              </div>
            </div>
          </div>
        )}
        {queues.map((queue) => (
          <div key={queue.queueId}>
            <div className="q-title">
              <span style={{ fontWeight: "bold" }}>
                {queue.queueName}/{localStorage.getItem("cname")} [counter #
                {localStorage.getItem("cno")}]
              </span>
              <div>
                <span className="userMail">
                  {" "}
                  <img src={userIcon} alt="" />{" "}
                  {getAdminEmailFromLocalStorage()}
                </span>
              </div>
            </div>

            <hr className="hr-viewqueue" />
            <div className="first-section">
              <div
                style={{ display: "flex", gap: "2rem" }}
                className="first-section-div"
              >
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th className="header-actveTokens">
                          Active Tokens :
                          <span style={{ fontSize: "30px" }}>
                            {countActiveTokens(tokenDetails)}
                          </span>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th className="header-waiting">
                          Average Waiting Time :
                          <span
                            style={{ fontSize: "30px", paddingLeft: "6px" }}
                          >
                            {formatTime(queueAvgWait)}
                          </span>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div style={{ display: "flex", gap: "1rem" }}>
                {/*<button
                  className="close-counter-button"
                  onClick={() => setShowPopup(true)}>
                  Close Counter
        </button>*/}
                <button
                  className="manual-token-button"
                  onClick={handleManualTokenClick}
                >
                  Add Manual Token
                </button>
              </div>
            </div>
            {showSection &&
              (tokenDetails.length === 0 ||
                tokenDetails[tokenDetails.length - 1].tokenStatus !==
                  TokenStatus.COMPLETE) && (
                <table className="table-three">
                  <thead>
                    <tr
                      key={tokenDetails[activeTokenIndex]?.tokenId}
                      className="viewque-second-screen"
                    >
                      <th className="">
                        Current Token :
                        <span className="token-number">
                          {getTokenInfoToShow("number")}
                        </span>
                      </th>

                      <th>
                        <button
                          className="table-button2"
                          onClick={handleCancelButtonClick}
                          style={{
                            opacity:
                              tokenDetails.length > 0 &&
                              tokenDetails[tokenDetails.length - 1]
                                .tokenStatus === TokenStatus.COMPLETE
                                ? 0.5
                                : 1,
                            cursor:
                              tokenDetails.length > 0 &&
                              tokenDetails[tokenDetails.length - 1]
                                .tokenStatus === TokenStatus.COMPLETE
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          {cancelText}
                        </button>
                      </th>
                      <th>
                        <button
                          className="table-button1"
                          onClick={handleNextButtonClick}
                          style={{
                            backgroundColor: buttonBackgroundColor,
                            opacity:
                              tokenDetails.length > 0 &&
                              tokenDetails[tokenDetails.length - 1]
                                .tokenStatus === TokenStatus.COMPLETE
                                ? 0.5
                                : 1,
                            cursor:
                              tokenDetails.length > 0 &&
                              tokenDetails[tokenDetails.length - 1]
                                .tokenStatus === TokenStatus.COMPLETE
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          {buttonTexts[buttonTextIndex]}
                        </button>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}

            <table className="table-four">
              <thead>
                <tr>
                  <th className="token-header">Token Number</th>
                  <th className="token-header">Token Status</th>
                  <th className="token-header">Counter Number</th>
                </tr>
              </thead>
              <tbody>
                {tokenDetails.map((token) => (
                  <tr key={token.tokenId} className="tr-main">
                    <td className="td-main">{token.tokenNumber}</td>
                    <td
                      className="td-main"
                      style={{
                        color: getStatusTextColor(token.tokenStatus),
                      }}
                    >
                      {token.tokenStatus === TokenStatus.COMPLETE
                        ? "Complete"
                        : mapTokenStatus(token.tokenStatus)}
                    </td>
                    <td className="td-main">{token.tokenCounterNo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <ToastContainer />

        {/* Popup */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content scale-up">
              <div className="popup-header">
                <div className="popup-top-div">
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                      padding: "20px",
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                    icon={faXmark}
                    onClick={() => setShowPopup(false)}
                  />
                  <h2 className="popup-title">Are you sure?</h2>
                </div>
              </div>
              <p className="popup-para">
                Do you wish to close this counter? If so, please ensure that all
                ongoing services are concluded before proceeding.
              </p>
              <div className="button-flex">
                <button
                  className="inform-button"
                  onClick={() => setShowPopup(false)}
                >
                  Inform Waiting Users
                </button>
                <button className="close-button" onClick={handleCloseCounter}>
                  Close Counter
                </button>
              </div>
            </div>
          </div>
        )}
        {timerShowPopup && (
          <div className="popup-overlay">
            <div className="popup-content scale-up">
              <div className="popup-header">
                <div className="popup-top-div2">
                  {/* <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                      padding: "20px",
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                    icon={faXmark}
                    onClick={() => setShowPopup(false)}
                  /> */}
                  <h2>Wait is over !</h2>
                </div>
              </div>
              <p className="popup-para">
                You may now update the status of Token number{" "}
                <strong>{waitPopupToken}</strong>
                <br />
                <span>Cancel or Attend</span>
              </p>
              <div className="button-flex2">
                <button className="ok-button" onClick={handleClosePopup}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        {showSecondPopup && (
          <div className="popup-overlay">
            <div className="popup-content scale-up">
              <div className="popup-div">
                <h2 className="popup-title-two">Counter closed</h2>
              </div>
              <p className="popup-para-two">
                Do you wish to open this counter?
              </p>
              <button
                className="close-button inform"
                onClick={handleOpenCounter}
              >
                Open Counter
              </button>
            </div>
          </div>
        )}
        {showManualTokenPopup && (
          <div className="popup-overlay">
            <div className="popup-content scale-up">
              {loading && (
                <div className="loader-overlay">
                  <div className="loader-content">
                    <div className="loader-spinner"></div>
                    <p className="loading-text">Loading . . .</p>
                  </div>
                </div>
              )}
              <h2 className="popup-title">Add Manual Token</h2>
              <div className="update-form-input">
                <label className="label-popup">Mobile Number *</label>
                <input
                  type="number"
                  placeholder="Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  required
                  pattern="[0-9]{15}"
                />
                <label className="label-popup">Name</label>

                <input
                  type="text"
                  placeholder="Name"
                  value={userName}
                  onChange={(e) => setuserName(e.target.value)}
                />
                <label className="label-popup">Mail Id</label>

                <input
                  type="email"
                  placeholder="Mail Id"
                  value={emailField}
                  onChange={(e) => setemailField(e.target.value)}
                />
                {accompanyMembers && (
                  <input
                    type="number"
                    placeholder="Accompanying Members"
                    value={accompanyingMembers}
                    onChange={(e) => setAccompanyingMembers(e.target.value)}
                  />
                )}
              </div>

              <div className="update-btn-div">
                <button className="close-button" onClick={handlePopupClose}>
                  Cancel
                </button>
                <button
                  className="inform-button"
                  onClick={handleManualTokenSubmit}
                >
                  Create Token
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>
        )}
        {showTokenNumberPopup && (
          <div className="popup-overlay">
            <div className="popup-content scale-up">
              <div className="update-form-input">
                {tokenNumber !== undefined && (
                  <p className="manual-token">Token Number: {tokenNumber}</p>
                )}
              </div>
              <div className="update-btn-div">
                <button className="inform-button" onClick={handlePopupClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewQueue;
